import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'
import { getConfiguration } from '../utils/ConfigurationProvider'

const browserHistory = createBrowserHistory ( { basename: '' } )
const app = document.getElementById( 'SignInHeader-LogIn' )
const config = getConfiguration( app )

export var reactPlugin = new ReactPlugin ()
const appInsights = new ApplicationInsights ( {
  config: {
    enableAutoRouteTracking: true,
    connectionString: config.appInsightsConnectionString || process.env.REACT_APP_INSIGHTS_CONNECTION_STRING,
    extensions: [ reactPlugin ],
    extensionConfig: {
      [ reactPlugin.identifier ]: { history: browserHistory }
    }
  }
} )
appInsights.loadAppInsights()
