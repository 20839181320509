import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { BrowserRouter as Router } from 'react-router-dom'
import './index.scss'
import App from './App'
import { getConfiguration } from './utils/ConfigurationProvider'

export const init = async ( ) => {

    const app = document.getElementById( 'SignInHeader-LogIn' )
    const config = getConfiguration( app )
    
    const formToken = config.formToken || ''
    if ( formToken.length ) {
        axios.defaults.headers.common[ config.antiForgeryHeaderName || '__RequestVerificationToken' ] = formToken
    }   

    const LDProvider = await asyncWithLDProvider( {
        clientSideID: config.launchDarklyClientKey || process.env.REACT_APP_LAUNCHDARKLY_KEY,
        reactOptions: {
            useCamelCaseFlagKeys: false
        },
        context: {
            kind: 'user',
            key: 'ESLayoutUI',
            name: 'ESLayoutUI',
            email: ''
        },
        options: {}
    } )

    ReactDOM.render(
        <Router>
            <LDProvider>                
                <React.StrictMode>
                    <App config={ config } />                        
                </React.StrictMode>
            </LDProvider>
        </Router>,
        app
    )
}

init( )