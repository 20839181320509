import PropTypes from 'prop-types'
import axios from 'axios'

export default function HomeUrlAPI ( props ) {
    const { apiUrl } = props

    return new Promise( async ( resolve ) => {
        try {            
            const response = await axios.get( apiUrl )
            resolve( response.data )
        } catch ( e ) {
            console.log( e )
            resolve( '' )
        }
    } )
}

HomeUrlAPI.propTypes = {
    apiUrl: PropTypes.string.isRequired
}