export const getConfiguration = ( app ) => {
    let config = {
      oktaClientId: process.env.REACT_APP_OKTA_CLIENT_ID ?? '',
      oktaIssuer: process.env.REACT_APP_OKTA_ISSUER ?? '',      
      launchDarklyClientKey: process.env.REACT_APP_LAUNCHDARKLY_KEY ?? ''
    }

    const dataConfigFromExternal = app.attributes[ 'data-config' ]?.value ?? '' 
  
    if ( dataConfigFromExternal.length > 0 ) {
      const configFromExternal = JSON.parse( dataConfigFromExternal )
      config = { ...config, ...configFromExternal }
    }
  
    return config
}