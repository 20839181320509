import './App.css'
import React from 'react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import PropTypes from 'prop-types'
import { reactPlugin } from './AppInsights'
import './App.scss'
import LogIn from './components/logIn/LogIn'
import { FF_LOGIN_AUTH_JWT_TOKEN_ENABLED } from './constants'

function App( props ) {
  const config = props.config || {}
  const LoginComponent = () => <LogIn config={ config } />

  try {
    const ldClient = props.ldClient || {}
    if ( ldClient.allFlags()[ FF_LOGIN_AUTH_JWT_TOKEN_ENABLED ] ) {
      const oktaAuth = new OktaAuth( {
        clientId: config.oktaClientId || process.env.REACT_APP_OKTA_CLIENT_ID,
        issuer: config.oktaIssuer || process.env.REACT_APP_OKTA_ISSUER,
        redirectUri: `${ window.location.origin }${ process.env.REACT_APP_LOG_IN_URL }`,
        pkce: true,
      } )
      const restoreOriginalUri = async ( _oktaAuth, originalUri ) => {
        history.replace( toRelativeUrl( originalUri || '/', window.location.origin ) )
      }
      return (
          <Security
              oktaAuth={ oktaAuth }
              restoreOriginalUri={ restoreOriginalUri }
          >
              <LoginComponent />
          </Security>
      )
    }
  } catch ( e ) {

  }
  return <LoginComponent />
}

export default withLDConsumer()( withAITracking( reactPlugin, App ) )

App.propTypes = {
  config: PropTypes.shape( {
    homeUrl: PropTypes.string,
    kubraLogoutUrl: PropTypes.string
  } ),
  ldClient: PropTypes.instanceOf( Object ),
}
